import axios from 'axios';
import { toast } from 'react-toastify';
import { API_BASE_URL } from "../constants/configuration";
import { updateRedux } from './commonReducer';
import axiosInstance from '../utils/axiosInterceptor';
import axiosFormInstance from '../utils/axiosFormInterceptor'

const formHeader = {
  headers: {
    'Content-Type': 'multipart/form-data',
    'Authorization': `Bearer ${localStorage.getItem('token')}`
  },
}

const header = {
  headers: {
    'Authorization': `Bearer ${localStorage.getItem('token')}`
  },
}

export const login = (data, callback) => (dispatch) => {
  dispatch(updateRedux({ key: 'login_loading', value: true }))
  axios.post(`${API_BASE_URL}admin/login`, data).then(res => {
    if (res.data.status) {
      dispatch(updateRedux({ key: 'login_loading', value: false }))
      localStorage.setItem('token', res.data.accessToken)
      localStorage.setItem('username', res.data.username)
      localStorage.setItem('name', res.data.name)
      callback(res)
      toast.success("Logged in successfully", {
        position: "bottom-center",
        autoClose: 3000
      })
    } else {
      dispatch(updateRedux({ key: 'login_loading', value: true }))
      toast.error(res.data.message, { position: "bottom-center", autoClose: 3000 })
    }
  }).catch((err) => { console.log(err) })
}

export const getIncidents = (formData, callback) => (dispatch) => {
  dispatch(updateRedux({ key: 'incident_lists_loading', value: true }))
  axiosFormInstance.post(`${API_BASE_URL}admin/getIncidents`, formData).then(res => {
    dispatch(updateRedux({ key: 'incident_lists_loading', value: false }))
    if (res.data.status == true) {
      dispatch(updateRedux({ key: 'incident_lists', value: res.data.incidents }))
      dispatch(updateRedux({ key: 'incidentCount', value: res.data.pages }))
      dispatch(updateRedux({ key: 'incidentTableStatus', value: res.data.page_status }))
      if (callback) { callback(res.data.data) }
    } else {
      toast.error(res.data.message, { position: "bottom-center", autoClose: 3000 })
      if (callback) { callback(res.data.data) }
    }
  }).catch((err) => {
    console.log(err)
    dispatch(updateRedux({ key: 'incident_lists_loading', value: false }))
  })
}

export const getIncidentsNew = (formData, callback) => (dispatch) => {
  dispatch(updateRedux({ key: 'incident_lists_loading', value: true }))
  axiosFormInstance.post(`${API_BASE_URL}admin/getIncidents-new`, formData).then(res => {
    dispatch(updateRedux({ key: 'incident_lists_loading', value: false }))
    if (res.data.status == true) {
      dispatch(updateRedux({ key: 'incident_lists', value: res.data.incidents }))
      dispatch(updateRedux({ key: 'incidentCount', value: res.data.pages }))
      dispatch(updateRedux({ key: 'incidentTableStatus', value: res.data.page_status }))
      if (callback) { callback(res.data.data) }
    } else {
      toast.error(res.data.message, { position: "bottom-center", autoClose: 3000 })
      if (callback) { callback(res.data.data) }
    }
  }).catch((err) => {
    console.log(err)
    dispatch(updateRedux({ key: 'incident_lists_loading', value: false }))
  })
}

export const getReturnedIncidents = (formData, callback) => (dispatch) => {
  dispatch(updateRedux({ key: 'incident_lists_loading', value: true }))
  axiosFormInstance.post(`${API_BASE_URL}admin/getReturnedIncidents`, formData).then(res => {
    dispatch(updateRedux({ key: 'incident_lists_loading', value: false }))
    if (res.data.status == true) {
      dispatch(updateRedux({ key: 'returned_incident_lists', value: res.data.incidents }))
      dispatch(updateRedux({ key: 'returnedIncidentCount', value: res.data.pages }))
      dispatch(updateRedux({ key: 'returnedIncidentTableStatus', value: res.data.page_status }))
      if (callback) { callback(res.data.data) }
    } else {
      toast.error(res.data.message, { position: "bottom-center", autoClose: 3000 })
      if (callback) { callback(res.data.data) }
    }
  }).catch((err) => {
    console.log(err)
    dispatch(updateRedux({ key: 'incident_lists_loading', value: false }))
  })
}


export const getReturnedIncidentsNew = (formData, callback) => (dispatch) => {
  dispatch(updateRedux({ key: 'incident_lists_loading', value: true }))
  axiosFormInstance.post(`${API_BASE_URL}admin/getReturnedIncidents-new`, formData).then(res => {
    dispatch(updateRedux({ key: 'incident_lists_loading', value: false }))
    if (res.data.status == true) {
      dispatch(updateRedux({ key: 'returned_incident_lists', value: res.data.incidents }))
      dispatch(updateRedux({ key: 'returnedIncidentCount', value: res.data.pages }))
      dispatch(updateRedux({ key: 'returnedIncidentTableStatus', value: res.data.page_status }))
      if (callback) { callback(res.data.data) }
    } else {
      toast.error(res.data.message, { position: "bottom-center", autoClose: 3000 })
      if (callback) { callback(res.data.data) }
    }
  }).catch((err) => {
    console.log(err)
    dispatch(updateRedux({ key: 'incident_lists_loading', value: false }))
  })
}

export const downloadAll = (data, filename) => (dispatch) => {
  dispatch(updateRedux({ key: 'download_loading', value: true }))
  axios.post(`${API_BASE_URL}admin/downloadIncidents`, data, {
    responseType: 'blob',
    headers: {
      'Authorization': `Bearer ${localStorage.getItem("token")}`
    },
  }).then((res) => {
    dispatch(updateRedux({ key: 'download_loading', value: false }))
    if (res.status == 200) {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        filename ? filename : 'incidents.zip',
      );
      document.body.appendChild(link);
      link.click();
      toast.success("Incidents downloaded successfully", {
        position: "bottom-center",
        autoClose: 3000
      })
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err) => {
    console.log(err)
  })
}



export const downloadSingleIncident = (data, filename) => (dispatch) => {
  dispatch(updateRedux({ key: 'download_loading', value: true }))
  axios.post(`${API_BASE_URL}admin/downloadSingleIncident`, data, {
    responseType: 'blob',
    headers: {
      'Authorization': `Bearer ${localStorage.getItem("token")}`
    },
  }).then((res) => {
    dispatch(updateRedux({ key: 'download_loading', value: false }))
    if (res.status == 200) {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        filename ? filename : 'incidents.zip',
      );
      document.body.appendChild(link);
      link.click();
      toast.success("Incident downloaded successfully", {
        position: "bottom-center",
        autoClose: 3000
      })
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err) => {
    console.log(err)
  })
}



// ADD INCIDENT SECTION

export const insertIncidentType = (data, callback) => () => {
  axiosInstance.post(`${API_BASE_URL}admin/insertIncidentType`, data).then(res => {
    if (res.data.status == true) {
      toast.success(res.data.message, { position: "bottom-center", autoClose: 3000 })
      callback && callback()
    } else {
      toast.error(res.data.message, { position: "bottom-center", autoClose: 3000 })
    }
  }).catch((err) => { console.log(err) })
}

export const getIncidentType = (formData) => (dispatch) => {
  dispatch(updateRedux({ key: 'incidentType_lists_loading', value: true }))
  axiosFormInstance.post(`${API_BASE_URL}admin/getIncidentType`, formData).then(res => {
    dispatch(updateRedux({ key: 'incidentType_lists_loading', value: false }))
    if (res.data.status) {
      dispatch(updateRedux({ key: 'incidentType_lists', value: res.data.incident_type_details }))
      toast.success(res.data.message, { position: "bottom-center", autoClose: 3000 })
    } else {
      toast.error(res.data.message, { position: "bottom-center", autoClose: 3000 })
    }
  }).catch((err) => {
    dispatch(updateRedux({ key: 'incidentType_lists_loading', value: false }))
    console.log(err)
  })
}

export const deleteIncidentType = (id, callback) => () => {
  axiosInstance.post(`${API_BASE_URL}admin/deleteIncidentType`, id).then(res => {
    if (res.data.status == true) {
      toast.success('Incident type deleted successfully', { position: "bottom-center", autoClose: 3000 })
      if (callback) {
        callback()
      }
    } else {
      toast.error(res.data.message, { position: "bottom-center", autoClose: 3000 })
    }
  }).catch((err) => { console.log(err) })
}

export const getIncidentTypeDetails = (id) => (dispatch) => {
  axiosInstance.get(`${API_BASE_URL}admin/getIncidentTypeDetail/${id}`).then((res) => {
    if (res.data.status) {
      dispatch(updateRedux({ key: "editIncidentTypeDetails", value: res.data.incident_type_details }))
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err) => {
    console.log(err.response, "6")
  })
}

export const updateIncidentType = (data, callback) => () => {
  axiosInstance.post(`${API_BASE_URL}admin/updateIncidentType`, data).then(res => {
    if (res.data.status) {
      toast.success(res.data.message, { position: "bottom-center", autoClose: 3000 })
      if (callback) {
        callback()
      }
    } else {
      toast.error(res.data.message, { position: "bottom-center", autoClose: 3000 })
    }
  }).catch((err) => { console.log(err) })
}

export const updateSmsText = (formdata, callback) => () => {
  axiosFormInstance.post(`${API_BASE_URL}admin/smsUpdateText`, formdata).then(res => {
    if (res.data.status) {
      toast.success(res.data.message, { position: "bottom-center", autoClose: 3000 })
      if (callback) {
        callback(res.data)
      }
    } else {
      toast.error(res.data.message, { position: "bottom-center", autoClose: 3000 })
    }
  }).catch((err) => { console.log(err) })
}

export const getSmsText = (callback) => () => {
  axiosInstance.get(`${API_BASE_URL}admin/getTexts`).then(res => {
    if (res.data.status) {
      // toast.success(res.data.message, { position: "bottom-center", autoClose: 3000 })
      if (callback) {
        callback(res.data)
      }
    } else {
      toast.error(res.data.message, { position: "bottom-center", autoClose: 3000 })
    }
  }).catch((err) => { console.log(err) })
}

export const returningToCustomer = (callback) => () => {
  axiosFormInstance.post(`${API_BASE_URL}admin/returningToCustomer`).then(res => {
    if (res.data.status) {
      toast.success(res.data.message, { position: "bottom-center", autoClose: 3000 })
      callback && callback(true, res.data.message)
    } else {
      toast.error(res.data.message, { position: "bottom-center", autoClose: 3000 })
      callback && callback(false, res.data.message)
    }
  }).catch((err) => { console.log(err) })
}

export const uploadReturnedIncidents = (data, callback) => () => {
  axiosFormInstance.post(`${API_BASE_URL}admin/uploadReturnedIncidents`, data).then(res => {
    if (res.data.status) {
      toast.success(res.data.message, { position: "bottom-center", autoClose: 3000 })
      callback && callback(true, res.data.message)
    } else {
      toast.error(res.data.message, { position: "bottom-center", autoClose: 3000 })
      callback && callback(false, res.data.message)
    }
  }).catch((err) => { console.log(err) })
}


// TERMS

export const addTerms = (data, callback) => () => {
  axiosFormInstance.post(`${API_BASE_URL}admin/updateTerms`, data).then(res => {
    if (res.data.status) {
      toast.success(res.data.message, { position: "bottom-center", autoClose: 3000 })
      callback && callback(true, res.data)
    } else {
      toast.error(res.data.message, { position: "bottom-center", autoClose: 3000 })
      callback && callback(false, res.data.message)
    }
  }).catch((err) => { console.log(err) })
}

export const getTerms = (callback) => () => {
  axiosInstance.get(`${API_BASE_URL}admin/getTerms`).then(res => {
    if (res.data.status) {
         callback && callback(res?.data?.terms_details)
    } else {
      toast.error(res.data.message, { position: "bottom-center", autoClose: 3000 })
      callback && callback(false, res.data.message)
    }
  }).catch((err) => { console.log(err) })
}
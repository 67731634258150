import React from 'react'
import { Spinner } from 'react-bootstrap'

const LoadingSpinner = ({height}) => {
    return (
        <div style={{width:'100%', height: height ? height: 200,display: 'flex',flexDirection: 'column', alignItems:'center', justifyContent:'center'}}>
            {/* <Spinner animation="grow" size="xl" /> */}
            <img src='/assets/img/dualBallLoader.gif' style={{width:'70px'}}/>
            {/* <h6>...Loadingasdf</h6> */}
        </div>
    )
}

export default LoadingSpinner
import React, { useEffect, useState } from 'react'
import Sidebar from '../components/Sidebar';
import Header from '../components/Header';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { isSideBarHidden } from '../redux/commonReducer';
import TitleBar from '../components/TitleBar/TitleBar';
import SubTitleBar from '../components/SubTitleBar/SubTitleBar';
import TextArea1 from '../components/TextArea1/TextArea1';
import { getSmsText, updateSmsText } from '../redux/actionCreator';
import Button1 from '../components/Button1/Button1';

export default function SmsText() {

    const dispatch = useDispatch()
    const sideBarHidden = useSelector(isSideBarHidden)

    const [inputs, setInputs] = useState({
        otp_sms_en: '',
        otp_sms_ar: '',
        return_sms_en: '',
        return_sms_ar: '',
        incident_sms_en: '',
        incident_sms_ar: '',

        dubai_now_push_en: '',
        dubai_now_push_ar: '',

        dubai_now_sms_en: '',
        dubai_now_sms_ar: '',
    })

    useEffect(() => {
        getData()
    }, [])

    function getData() {
        dispatch(getSmsText((res) => {
            setInputs({
                id: res.text_details._id,
                otp_sms_en: res.text_details.otp_english_text,
                otp_sms_ar: res.text_details.otp_arabic_text,
                return_sms_ar: res.text_details.return_arabic_text,
                return_sms_en: res.text_details.return_english_text,
                incident_sms_en: res.text_details.incident_create_english_text,
                incident_sms_ar: res.text_details.incident_create_arabic_text,

                dubai_now_push_en: res.text_details.dn_push_english_text,
                dubai_now_push_ar: res.text_details.dn_push_arabic_text,

                dubai_now_sms_en: res.text_details.dn_sms_english_text,
                dubai_now_sms_ar: res.text_details.dn_sms_arabic_text,
            })
        }))
    }

    function inputChangeHandler(field, value) {
        setInputs(s => ({ ...s, [field]: value }))
    }

    function submitHandler() {
        const formdata = new FormData()
        formdata.append('id', inputs.id)
        formdata.append('otp_english_text', inputs.otp_sms_en)
        formdata.append('otp_arabic_text', inputs.otp_sms_ar)
        formdata.append('return_english_text', inputs.return_sms_en)
        formdata.append('return_arabic_text', inputs.return_sms_ar)
        formdata.append('incident_create_english_text', inputs.incident_sms_en)
        formdata.append('incident_create_arabic_text', inputs.incident_sms_ar)

        formdata.append('dn_push_english_text', inputs.dubai_now_push_en)
        formdata.append('dn_push_arabic_text', inputs.dubai_now_push_ar)

        formdata.append('dn_sms_english_text', inputs.dubai_now_sms_en)
        formdata.append('dn_sms_arabic_text', inputs.dubai_now_sms_ar)
        dispatch(updateSmsText(formdata, (res) => {
            getData()
        }))
    }


    return (
        (
            <div className="container-fluid pl-0">
                <div className={"row" + (sideBarHidden ? ' hide' : '')}>
                    <div className={"col right-sidebar" + (sideBarHidden ? ' hide' : '')}>
                        <Sidebar />
                    </div>
                    <div className={(sideBarHidden ? 'col-md-12  px-5' : 'col-md-9')}>
                        <div className="main-content">
                            <Header />
                            <div className="breadcrumbs">
                                <div className="lists">
                                    <ul>
                                        <li>
                                            <Link>SMS Text</Link>
                                        </li>
                                        <li>
                                            <Link to="/incident-request">
                                                Home <span>/</span>{" "}
                                            </Link>
                                        </li>
                                        <li>
                                            <Link>SMS Text</Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div>
                                <section className='mb-5'>
                                    <TitleBar Title={'Return SMS'} />
                                    <SubTitleBar SubTitle='English' />
                                    <TextArea1 Value={inputs.return_sms_en} onChange={(e) => inputChangeHandler('return_sms_en', e.target.value)} />
                                    <SubTitleBar SubTitle='Arabic' />
                                    <TextArea1 dir='rtl' Value={inputs.return_sms_ar} onChange={(e) => inputChangeHandler('return_sms_ar', e.target.value)} />
                                </section>
                                <section className='mb-5'>
                                    <TitleBar Title={'OTP SMS'} />
                                    <SubTitleBar SubTitle='English' />
                                    <TextArea1 Value={inputs.otp_sms_en} onChange={(e) => inputChangeHandler('otp_sms_en', e.target.value)} />
                                    <SubTitleBar SubTitle='Arabic' />
                                    <TextArea1 dir='rtl' Value={inputs.otp_sms_ar} onChange={(e) => inputChangeHandler('otp_sms_ar', e.target.value)} />
                                </section>
                                <section>
                                    <TitleBar Title={'INCIDENT TEXT'} />
                                    <SubTitleBar SubTitle='English' />
                                    <TextArea1 Value={inputs.incident_sms_en} onChange={(e) => inputChangeHandler('incident_sms_en', e.target.value)} />
                                    <SubTitleBar SubTitle='Arabic' />
                                    <TextArea1 dir='rtl' Value={inputs.incident_sms_ar} onChange={(e) => inputChangeHandler('incident_sms_ar', e.target.value)} />
                                </section>


                                {/* NEW FIRLD */}

                                <section className='mt-4'>
                                    <TitleBar Title={'DUBAI NOW PUSH NOTIFICATION'} />
                                    <SubTitleBar SubTitle='English' />
                                    <TextArea1 Value={inputs.dubai_now_push_en} onChange={(e) => inputChangeHandler('dubai_now_push_en', e.target.value)} />
                                    <SubTitleBar SubTitle='Arabic' />
                                    <TextArea1 dir='rtl' Value={inputs.dubai_now_push_ar} onChange={(e) => inputChangeHandler('dubai_now_push_ar', e.target.value)} />
                                </section>


                                <section>
                                    <TitleBar Title={'DUBAI NOW SMS'} />
                                    <SubTitleBar SubTitle='English' />
                                    <TextArea1 Value={inputs.dubai_now_sms_en} onChange={(e) => inputChangeHandler('dubai_now_sms_en', e.target.value)} />
                                    <SubTitleBar SubTitle='Arabic' />
                                    <TextArea1 dir='rtl' Value={inputs.dubai_now_sms_ar} onChange={(e) => inputChangeHandler('dubai_now_sms_ar', e.target.value)} />
                                </section>

                                
                            </div>
                            <div className='buttons d-flex gap-3 mt-4'>
                                <Button1 Text={'Update'} onClick={() => submitHandler()} />
                                <Button1 Text={'Cancel'} onClick={() => getData()} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    )
}

import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { isSideBarHidden, updateRedux } from '../redux/commonReducer'
import { Capitalize } from '../utils/helpers'
import menuButton from "../../src/assets/img/menu.svg"
import logouButton from "../../src/assets/img/logout.svg"

function Header() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const sideBarStatus = useSelector(isSideBarHidden)
  const name = localStorage.getItem('name')

  const logOut = () => {
    localStorage.removeItem('token')
    navigate('/login')
  }

  function hamburgerAction() {
    dispatch(updateRedux({ key: 'isSideBarHidden', value: !sideBarStatus }))
  }
  return (
    <div className='main__header'>
      <div className="menu">
        <img src={menuButton} onClick={hamburgerAction} alt="" style={{cursor: "pointer"}}/>
      </div>
      <div className="logout">
        {/* <img src="./assets/img/icon.svg" style={{height:"24px"}} alt="" /> */}
        <div className="name">
          <h6>
            {Capitalize(name)}
            </h6>
          {/* <p>IT Admin</p> */}
        </div>
        <img src={logouButton} style={{ cursor: "pointer", height: "20px" }} onClick={logOut} alt="" />
      </div>
    </div>
  )
}

export default Header
import { createSlice } from '@reduxjs/toolkit';

export const commonSlice = createSlice({
    name: 'commonReducer',
    initialState: {
        value: true,
        isSideBarHidden: false,
        dropdownList: [
            { id: 1, open: false }
        ],
        download_loading: false
    },
    reducers: {
        updateRedux: (state, data) => {
            state[data.payload.key] = data.payload.value
        },
    },
})
export const { updateRedux } = commonSlice.actions;


export const incident_lists = (state) => state.commonReducer.incident_lists;
export const incidentCount = (state) => state.commonReducer.incidentCount;
export const incidentTableStatus = (state) => state.commonReducer.incidentTableStatus;
export const incidentType_lists = (state) => state.commonReducer.incidentType_lists;
export const editIncidentTypeDetails = (state) => state.commonReducer.editIncidentTypeDetails;
export const dropdownList = (state) => state.commonReducer.dropdownList;
export const isSideBarHidden = (state) => state.commonReducer.isSideBarHidden;
export const download_loading = (state) => state.commonReducer.download_loading;
export const returned_incident_lists = (state) => state.commonReducer.returned_incident_lists;
export const returnedIncidentCount = (state) => state.commonReducer.returnedIncidentCount;
export const returnedIncidentTableStatus = (state) => state.commonReducer.returnedIncidentTableStatus;
export const incident_lists_loading = (state) => state.commonReducer.incident_lists_loading;
export const incidentType_lists_loading = (state) => state.commonReducer.incidentType_lists_loading;
export const login_loading = (state ) => state.commonReducer.login_loading;


export default commonSlice.reducer;
export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL ? process.env.REACT_APP_API_BASE_URL : "https://tsd.shtdevops.xyz/ecom-incident/"
export const editorConfig = {
  buttons: [
    "bold",
    "italic",
    "underline",
    "undo",
    "ul",
    "ol",
    "indent",
    "outdent",
  ],
  placeholder: "",
  askBeforePasteHTML: false,
  // forcePlainText: true,
  enter: "div",
  defaultActionOnPasteFromWord: "insert_only_text",
  defaultActionOnPaste: "insert_only_text",
  sanitize: function (text) {
    // Remove HTML tags from text
    return text.replace(/<[^>]*>/g, "");
  },
  // enter: "br",
};

export const editorConfigAra = {
  buttons: [
    "bold",
    "italic",
    "underline",
    "undo",
    "ul",
    "ol",
    "indent",
    "outdent",
  ],
  direction: "rtl",
  placeholder: "",
  askBeforePasteHTML: false,
  // forcePlainText: true,
  enter: "div",
  defaultActionOnPasteFromWord: "insert_only_text",
  defaultActionOnPaste: "insert_only_text",
  sanitize: function (text) {
    // Remove HTML tags from text
    return text.replace(/<[^>]*>/g, "");
  },
  // enter: "br",
};

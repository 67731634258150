import React, { useEffect, useState } from "react";
import Sidebar from "../components/Sidebar";
import { Link } from "react-router-dom";
import Table from "react-bootstrap/Table";
import { Form } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import Header from "../components/Header";
import { useDispatch, useSelector } from "react-redux";
import {
  downloadAll,
  downloadSingleIncident,
  getIncidents,
  getIncidentsNew,
} from "../redux/actionCreator";
import {
  download_loading,
  incidentCount,
  incidentTableStatus,
  incident_lists,
  incident_lists_loading,
  isSideBarHidden,
} from "../redux/commonReducer";
import moment from "moment";
import LoadingSpinner from "../components/LoadingSpinner/LoadingSpinner";
function IncidentRequest() {
  const dispatch = useDispatch();
  const sideBarHidden = useSelector(isSideBarHidden);
  const loading = useSelector(download_loading);
  const incidentLoading = useSelector(incident_lists_loading);

  const [page, setPage] = useState(0);
  const [keyword, setKeyword] = useState("");
  const [customShow, setCustomShow] = useState(10);
  const [downloadID, setDownloadID] = useState([]);
  const [sortColumn, setSortColumn] = useState("createdAt");
  const [sortOrder, setSortOrder] = useState(false);
  const [isAllChecked, setIsAllChecked] = useState(true);
  const [filterActive, setFilterActive] = useState()

  const incidentLists = useSelector(incident_lists);
  const pageCount = useSelector(incidentCount);
  const tableStatus = useSelector(incidentTableStatus);

  useEffect(() => {
    let formData = new FormData();
    formData.append("page", 0);
    formData.append("keyword", keyword);
    formData.append("perPage", customShow);
    formData.append("sort_order", sortOrder ? 1 : -1);
    formData.append("sort_key", sortColumn);
    dispatch(getIncidents(formData));
  }, []);

  const handlePageChange = (e) => {
    setDownloadID([])
    setPage(e.selected);
    if (e.selected >= 0 && page != e.selected) {
      let formData = new FormData();
      formData.append("page", e.selected);
      formData.append("keyword", keyword);
      formData.append("perPage", customShow);
      formData.append("sort_order", sortOrder ? 1 : -1);
      formData.append("sort_key", sortColumn);
      dispatch(getIncidents(formData));
    }
  };
  const handleSearchSubmit = () => {
    let formData = new FormData();
    formData.append("keyword", keyword);
    formData.append("perPage", customShow);
    formData.append("sort_order", sortOrder ? 1 : -1);
    formData.append("sort_key", sortColumn);
    dispatch(getIncidents(formData));
  };

  const handleClearHandler = () => {
    setKeyword("");
    let formData = new FormData();
    formData.append("keyword", "");
    formData.append("perPage", customShow);
    formData.append("sort_order", sortOrder ? 1 : -1);
    formData.append("sort_key", sortColumn);
    dispatch(getIncidents(formData));
  };

  const handlefilter = (e) => {
    setFilterActive(e.target.value)
    setKeyword("");
    let formData = new FormData();
    formData.append("keyword", "");
    formData.append("perPage", customShow);
    formData.append("sort_order", sortOrder ? 1 : -1);
    formData.append("sort_key", sortColumn);
    formData.append("source", e.target.value);
    dispatch(getIncidentsNew(formData));
  };

  const DownloadAll = () => {
    let data = { id: downloadID };
    if (downloadID?.length === 0) {
      data = { id: incidentLists?.map(item => item._id), };
    }
    dispatch(downloadAll(data));
  };

  const onSortHandler = (type) => {
    setSortOrder(!sortOrder);
    setSortColumn(type);
    const formData = new FormData();

    formData.append("page", page);
    formData.append("keyword", keyword);
    formData.append("perPage", customShow);
    formData.append("sort_order", !sortOrder ? 1 : -1);
    formData.append("sort_key", type);

    dispatch(getIncidents(formData));
  };

  const singleDownload = (id) => {
    const data = { id: id };
    dispatch(downloadSingleIncident(data));
  };
  const allChecked = (e) => {
    if (e.target.checked) {
      let id = [];
      incidentLists?.forEach((ele) => {
        id.push(ele?._id);
      });
      setDownloadID([...id]);
    } else {
      setDownloadID([]);
    }
  };

  const onLimitChangeHandler = (value) => {
    setCustomShow(parseInt(value));
    setPage(0);
    let formData = new FormData();
    formData.append("keyword", keyword);
    formData.append("perPage", value);
    formData.append("sort_order", sortOrder ? 1 : -1);
    formData.append("sort_key", sortColumn);
    dispatch(getIncidents(formData));
  };

  const onEnterSearch = (e) => {
    if (e.key == "Enter") {
      handleSearchSubmit();
    }
  };

  return (
    <>
      <div className="container-fluid pl-0">
        <div className={"row" + (sideBarHidden ? " hide" : "")}>
          <div className={"col right-sidebar" + (sideBarHidden ? " hide" : "")}>
            <Sidebar />
          </div>
          <div className={sideBarHidden ? "col-md-12  px-5" : "col-md-9"}>
            <div className="main-content">
              <Header />
              <div className="breadcrumbs">
                <div className="lists">
                  <ul>
                    <li>
                      <Link>Incident Requests</Link>
                    </li>
                    <li>
                      <Link>
                        Home <span>/</span>{" "}
                      </Link>
                    </li>
                    <li>
                      <Link>Incident Requests</Link>
                    </li>
                  </ul>
                </div>
                <button
                  className="btn btn-download"
                  onClick={() => DownloadAll()}
                // disabled={incidentLists?.length == 0}
                >
                  <img src="./assets/img/incident-dark.svg" alt="" /> Download
                  {downloadID?.length ? (" Selected") : " All"}
                </button>
              </div>
              <div className="default__table">
                <div className="table__filter">
                  {/* <form onSubmit={handleSearchSubmit}> */}
                  <div className="entries">
                    <p>Show</p>
                    <input
                      type="number"
                      value={customShow}
                      min={1}
                      onChange={(e) => {
                        if (e.target.value == 0) {
                          onLimitChangeHandler(1);
                        } else {
                          onLimitChangeHandler(e.target.value);
                        }
                      }}
                      className="form-control"
                    />
                    <p>entries</p>
                  </div>

                  <div className="source_filter">
                    <select className="form-select" value={filterActive} onChange={handlefilter}>
                      <option selected hidden value=''>Select Source</option>
                      <option value='web_dubainow'>Dubainow Web</option>
                      <option value='mobile_dubainow'>Dubainow App</option>
                      <option value='web'>Web</option>
                      <option value='mobile'>Mobile</option>
                    </select>
                    {filterActive && <button onClick={() => { setFilterActive(''); handleClearHandler() }}>×</button>}
                  </div>
                  {/* </form> */}
                  <div className="search">
                    {/* <form onSubmit={handleSearchSubmit}> */}
                    <img
                      src="./assets/img/search.svg"
                      style={{ cursor: "pointer" }}
                      onClick={() => handleSearchSubmit()}
                      alt=""
                    />
                    <input
                      type="text"
                      className="form-control"
                      value={keyword}
                      onChange={(e) => setKeyword(e.target.value)}
                      onKeyDown={(e) => onEnterSearch(e)}
                      placeholder="Search"
                    />
                    {keyword !== "" ? <span
                      style={{ cursor: "pointer", backgroundColor: 'white', zIndex: 9999, position: 'absolute', right: 5, width: 20, height: 20, textAlign: 'center' }}
                      onClick={() => handleClearHandler()}
                    >
                      X
                    </span> : ""}
                    {/* </form> */}
                  </div>
                </div>
                {(loading || incidentLoading) ? (
                  <LoadingSpinner />
                ) : (
                  <>
                    <Table bordered hover>
                      <thead className="thead-theme">
                        <tr>
                          <th style={{ width: "100px", textAlign: "center" }}>
                            <Form.Check
                              onClick={(e) => allChecked(e)}
                              aria-label="option 1"
                              checked={incidentLists?.length && incidentLists?.every(item => downloadID?.some(obj => obj === item._id))}
                            />
                          </th>
                          <th
                            style={{ width: "100px" }}
                            onClick={() => onSortHandler("incident_no")}
                            className={`sorting cursor-pointer 
                                                ${sortColumn == "incident_no" &&
                                sortOrder == true
                                ? "active"
                                : sortColumn ==
                                  "incident_no" &&
                                  sortOrder == false
                                  ? "inactive"
                                  : ""
                              }`}
                          >
                            Incident No
                          </th>
                          <th
                            style={{ width: "400px" }}
                            onClick={() => onSortHandler("incident_type")}
                            className={`sorting cursor-pointer  ${sortColumn == "incident_type" && sortOrder == true
                              ? "active"
                              : sortColumn == "incident_type" &&
                                sortOrder == false
                                ? "inactive"
                                : ""
                              }`}
                          >
                            Incident Type
                          </th>
                          <th
                            style={{ width: "400px", textAlign: "right" }}
                            onClick={() => onSortHandler("incident_type_ar")}
                            className={`sorting cursor-pointer  ${sortColumn == "incident_type_ar" &&
                              sortOrder == true
                              ? "active"
                              : sortColumn == "incident_type_ar" &&
                                sortOrder == false
                                ? "inactive"
                                : ""
                              }`}
                          >
                            نوع البلاغ
                          </th>
                          <th
                            style={{ width: "200px" }}
                            onClick={() => onSortHandler("incident_type_ar")}
                            className={`sorting cursor-pointer  ${sortColumn == "incident_type_ar" &&
                              sortOrder == true
                              ? "active"
                              : sortColumn == "incident_type_ar" &&
                                sortOrder == false
                                ? "inactive"
                                : ""
                              }`}
                          >
                            Source
                          </th>
                          <th
                            onClick={() => onSortHandler("requested_by")}
                            className={`sorting cursor-pointer 
                                                ${sortColumn ==
                                "requested_by" &&
                                sortOrder == true
                                ? "active"
                                : sortColumn ==
                                  "requested_by" &&
                                  sortOrder == false
                                  ? "inactive"
                                  : ""
                              }`}
                          >
                            Requested By
                          </th>
                          <th
                            style={{ width: "100px" }}
                            onClick={() => onSortHandler("created_date")}
                            className={`sorting cursor-pointer 
                                                ${sortColumn ==
                                "created_date" &&
                                sortOrder == true
                                ? "active"
                                : sortColumn ==
                                  "created_date" &&
                                  sortOrder == false
                                  ? "inactive"
                                  : ""
                              }`}
                          >
                            created date
                          </th>
                          {/* <th>status</th> */}
                          <th style={{ width: "100px" }}>download</th>
                        </tr>
                      </thead>
                      <tbody>
                        {incidentLists?.map((ele, i) => {
                          return (
                            <tr key={i}>
                              <td
                                style={{ width: "100px", textAlign: "center" }}
                              >
                                <Form.Check
                                  aria-label="option 1"
                                  checked={
                                    downloadID.includes(ele._id) ? true : false
                                  }
                                  onChange={(event) => {
                                    setIsAllChecked(false)
                                    if (event.target.checked) {
                                      setDownloadID([...downloadID, ele?._id]);
                                    } else {
                                      setDownloadID(
                                        downloadID.filter(
                                          (item) => item != ele?._id
                                        )
                                      );
                                    }
                                  }}
                                />
                              </td>
                              <td>{ele?.incident_no}</td>
                              <td className="type">
                                {ele?.incident_type[0]?.["incident_type"]}
                              </td>
                              <td className="type" dir="rtl">
                                {ele?.incident_type[0]?.["incident_type_ar"]}
                              </td>
                              <td style={{ textTransform: 'capitalize' }}>{ele?.source === 'mobile_dubainow' ? 'Dubainow Mobile' : ele?.source === 'web_dubainow' ? 'Dubainow Web' : ele?.source}</td>
                              <td>{ele?.first_name + " " + ele?.last_name}</td>
                              <td>
                                {moment
                                  .utc(ele?.created_date)
                                  .format("DD MMM YYYY")}
                              </td>
                              {/* {console.log(ele, 'elelment')} */}
                              {/* <td>
                            <button className="btn btn-sm btn-open">
                              Open
                            </button>
                          </td> */}
                              <td>
                                {downloadID?.length === 0 ? <a
                                  className="download"
                                  onClick={() => singleDownload(ele?._id)}
                                >
                                  Download
                                </a> : ""}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                    {incidentLists?.length == 0 && (
                      <h2 className="no-data">No Data Found!</h2>
                    )}
                    <div className="default-pagination d-flex flex-row-reverse align-items-center justify-content-between mt-5">
                      {pageCount > 1 ? (
                        <ReactPaginate
                          previousLabel={<>❮</>}
                          nextLabel={<>❯</>}
                          pageClassName="page-item"
                          pageLinkClassName="page-link"
                          previousClassName="page-item"
                          previousLinkClassName="page-link"
                          nextClassName="page-item"
                          nextLinkClassName="page-link"
                          // breakLabel="..."
                          breakClassName="page-item"
                          breakLinkClassName="page-link"
                          pageCount={pageCount}
                          marginPagesDisplayed={1}
                          pageRangeDisplayed={5}
                          initialPage={page}
                          onPageChange={(e) => handlePageChange(e)}
                          containerClassName="pagination"
                          activeClassName="pageActive"
                        />
                      ) : null}
                      {tableStatus === "No Records Found" ? "" : <p className="m-0 align-self-start">{tableStatus}</p>}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default IncidentRequest;

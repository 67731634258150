import React from 'react'
import './SubTitleBar.scss'
import { useState } from 'react'
import { useEffect } from 'react'

export default function SubTitleBar({ SubTitle = '' }) {

  const [title, setTitle] = useState('')

  useEffect(() => {
    setTitle(SubTitle)
    return () => setTitle('')
  }, [SubTitle])

  return (
    <h5 id='SubTitleBar'>
      {title}
    </h5>
  )
}

import React, { useEffect, useState } from 'react'
import './Button1.scss'

export default function Button1({ Text, Icon, onClick, reverse }) {

  const [text, setText] = useState(null)
  const [icon, setIcon] = useState(null)

  useEffect(() => {
    setText(Text)
  }, [Text])

  useEffect(() => {
    setIcon(Icon)
  }, [Icon])

  return (
    <button id='Button1' className={Text + (reverse ? ' reverse' : '')} onClick={(e) => onClick && onClick()}>
      {text}
      {icon && <img src={icon} alt="" />}
    </button >
  )
}

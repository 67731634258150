import React, { useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getIncidentTypeDetails,
  updateIncidentType,
} from "../redux/actionCreator";
import { editIncidentTypeDetails, isSideBarHidden } from "../redux/commonReducer";

const EditIncidentType = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const details = useSelector(editIncidentTypeDetails);
  const sideBarHidden = useSelector(isSideBarHidden)

  const [data, setData] = useState({});
  const [err, setErr] = useState({});

  useEffect(() => {
    if (id) {
      dispatch(getIncidentTypeDetails(id));
    }
  }, [id]);

  useEffect(() => {
    if (details) {
      setData({
        ...data,
        ...details,
      });
    }
  }, [details]);

  const onChangeHandler = (key, value) => {
    setData({
      ...data,
      [key]: value,
    });
  };

  const validate = () => {
    let temp = {};
    if (data.incident_type?.trim()?.length == 0) {
      temp = { ...temp, incident_type: "Please Add Incident Type English" };
    }
    if (data.incident_type_ar?.trim()?.length == 0) {
      temp = { ...temp, incident_type_ar: "Please Add Incident Type Arabic" };
    }

    if (Object.values(temp).length != 0) {
      setErr(temp);
      return false;
    } else {
      return true;
    }
  };

  const onSubmitHandler = () => {
    if (validate()) {
      const value = {
        id: data?._id,
        incident_type: data?.incident_type,
        incident_type_ar: data?.incident_type_ar,
        show_website: data?.show_website,
      };
      console.log(value)
      dispatch(updateIncidentType(value, () => {
        navigate("/incident-type-lists")
      }));
    }
  };

  return (
    (
      <div>
        <div className="container-fluid pl-0">
          <div className={"row" + (sideBarHidden ? ' hide' : '')}>
            <div className={"col right-sidebar" + (sideBarHidden ? ' hide' : '')}>
              <Sidebar />
            </div>
            <div className={(sideBarHidden ? 'col-md-12  px-5' : 'col-md-9')}>
              <div className="main-content">
                <Header />
                <div className="breadcrumbs">
                  <div className="lists">
                    <ul>
                      <li>
                        <Link>Edit Incident Type</Link>
                      </li>
                      <li>
                        <Link to="/incident-request">
                          Home <span>/</span>{" "}
                        </Link>
                      </li>
                      <li>
                        <Link>Edit Incident Type</Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div
                  className="incident__search"
                  style={{ backgroundColor: "#fff", border: "none" }}
                >
                  <div className="row align-items-center">
                    <div className="col-md-5">
                      <label htmlFor="">Incident Type English</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Incident Type"
                        value={data.incident_type}
                        onChange={(e) =>
                          onChangeHandler("incident_type", e.target.value)
                        }
                      />
                      <span className="error-msg">{err.incident_type}</span>
                    </div>
                    <div className="col-md-5">
                      <label htmlFor="">Incident Type Arabic</label>
                      <input
                        type="text"
                        className="form-control"
                        value={data.incident_type_ar}
                        onChange={(e) =>
                          onChangeHandler("incident_type_ar", e.target.value)
                        }
                        placeholder="Enter Incident Type"
                      />
                      <span className="error-msg">{err.incident_type_ar}</span>
                    </div>
                    <div className="col-md-12 mt-3">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        // {...register("display")}
                        value={data.show_website}
                        onChange={(e) =>
                          onChangeHandler(
                            "show_website",
                            e.target.checked ? "1" : "0"
                          )
                        }
                        checked={data.show_website == 1}
                        // value="1"
                        id="check"
                      />
                      <label
                        htmlFor="check"
                        style={{ marginLeft: "10px", fontSize: "16px" }}
                      >
                        Display in Public facing website
                      </label>
                    </div>
                    <div className="col-md-2 mt-4 d-flex align-items-center">
                      <button
                        className="btn btn-search"
                        type="submit"
                        onClick={() => onSubmitHandler()}
                      >
                        Submit
                      </button>
                      <Link to="/incident-type-lists">
                        <button className="btn btn-danger">Cancel</button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default EditIncidentType;

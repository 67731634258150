import React, { useEffect, useRef, useState } from 'react'
import './TextArea1.scss'

export default function TextArea1({ Value, onChange, Placeholder, ...Attributes }) {

  const [value, setValue] = useState('')
  // const ref = useRef()

  useEffect(() => {
    setValue(Value)
    // adjustTextareaHeight(ref.current)
  }, [Value])

  // function adjustTextareaHeight(e) {
  //   // e.style.overflow = 'hidden'
  //   e.style.height = 'auto'
  //   e.style.height = e.scrollHeight + 'px'
  // }
  function onChangeHandler(e) {
    setValue(e.target.value)
    onChange && onChange(e)
  }

  return (
    <textarea id='TextArea1'
      {...Attributes}
      // ref={ref}
      rows={12}
      value={value}
      placeholder={Placeholder}
      onChange={(e) => onChangeHandler(e)}
    // onInput={(e) => adjustTextareaHeight(e.target)}
    >
    </textarea>
  )
}